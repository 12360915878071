




















































































































































































































































































































































































































































































































































































































































.v-select {
  background-color: #ffffff;
  border-color: #ffffff;
  border-radius: 5px;
  .vs__selected {
    background-color: #0c83e9;
    border-color: #0c83e9;
    color: #ffffff;
    .vs__deselect {
      fill: #000;
      svg {
        width: 9px;
        height: 9px;
      }
    }
  }
}
.btn-info {
  color: #fff;
  background-color: #0c83e9;
  border-color: #0c83e9;
}
.btn-animation-info {
  color: #fff;
  background-color: #0c83e9;
  transition: 0.3s;
  border-color: #0c83e9;
  animation: glowing 2s linear infinite;
}
.btn-animation-info:hover {
  color: #fff;
  transform: scale(1.1);
}
.button-active {
  width: 75%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 10px;
  padding: 0;
  min-height: 40px;
  min-width: 275px;
  font-size: 1.3em;
  font-weight: 700;
  transition: 0.3s;
  animation: glowing 2s linear infinite;
}
@keyframes glowing {
  0% {
    box-shadow: 0 0px 30px #0c83e9;
  }
  50% {
    box-shadow: 0 0px 0px #0c83e9;
  }
  100% {
    box-shadow: 0 0px 30px #0c83e9;
  }
}
.btn-info:hover {
  color: #fff;
  background-color: #0c83e9;
  border-color: #0c83e9;
  box-shadow: 0 0px 20px #0c83e9;
}
.min-width-160 {
  min-width: 160px;
}
.form-group {
  legend {
    color: #fff;
  }
  .checkbox-group {
    min-height: 28px;
    display: flex;
    align-items: center;
  }
  .checkbox-group .custom-control-label {
    color: #fff;
  }
  .checkbox-group input[type='checkbox'] {
    width: 28px;
    height: 28px;
  }
  .checkbox-group .custom-control-label::after,
  .checkbox-group .custom-control-label::before {
    border-radius: 0;
    width: 28px;
    height: 28px;
    left: -2.8rem;
    top: 0;
  }
  .checkbox-group.ml-lg {
    padding-left: 2.8rem !important;
  }
  .d-flex {
    .checkbox-group {
      width: 38px;
      height: 38px;
      display: block;
      padding: 0.375rem 0.75rem;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      color: #495057;
      background-color: #ffffff;
      background-clip: padding-box;
      border: 1px solid #ced4da;
      border-radius: 0.25rem;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      // -ms-transform: scale(1.2); /* IE */
      // -moz-transform: scale(1.2); /* FF */
      // -webkit-transform: scale(1.2); /* Safari and Chrome */
      // -o-transform: scale(1.2); /* Opera */
      // transform: scale(1.2);
      margin-right: 20px;
    }
  }
  .form-control {
    color: #444;
  }
  .multiselect__tags {
    min-height: 80px;
  }
  .multiselect__tag {
    background: #0c83e9;
    .multiselect__tag-icon:after {
      color: #000;
    }
  }
}
.box-setting-bot {
  position: relative;
  border: 1px #fff solid;
  padding: 20px;
  border-radius: 15px;
  .box-header {
    color: #fff;
    text-align: center;
    margin-bottom: 25px;
    h3 {
      color: rgb(242, 234, 14);
      font-size: 22px;
      text-transform: uppercase;
      font-weight: bold;
    }
  }
  .box-type {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    button {
      width: 48%;
      background-color: #f2ea0e;
      color: #f00;
      font-weight: bold;
      letter-spacing: 2px;
      opacity: 0.5;
      &:hover {
        opacity: 1;
      }
      &.button-tab-active {
        background-color: #f2ea0e;
        color: #f00;
        opacity: 1;
      }
    }
  }
}
.button-group {
  .btn {
    margin: 0 5px;
    min-width: 160px;
    width: auto;
  }
  .btn-info {
    color: #fff;
    background-color: #0c83e9;
    border-color: #0c83e9;
  }
}
.Referral-card {
  .ref-id {
    h4 {
      margin-bottom: 8px;
      text-transform: capitalize;
      letter-spacing: 0.02em;
      font-size: 18px;
      font-weight: 600;
      margin-top: 0;
      color: #6c757d;
      text-shadow: 0 0 1px rgba(0, 0, 0, 0.1);
      span {
        font-size: 22px;
        color: #2f394e;
        font-weight: 600;
      }
    }
  }
  .boxref {
    width: 100%;
    span {
      font-size: 17px;
      color: #6c757d;
      font-weight: 600;
    }
    .ref-link {
      margin-top: 5px;
      background: #009750;
      border-radius: 5px;
      .input-group-text {
        padding: 0;
        border: 0;
        button {
          width: 70px;
          height: 40px;
          padding: 0;
          background: #8dc63f;
          border: 0;
          outline: none;
          border-radius: 5px 0 0 5px;
          box-shadow: 0px 0px 5px 1px #00000043;
          font-weight: 550;
        }
      }
      input {
        height: 40px;
        border: 0;
        background: transparent;
        color: #fff;
        font-weight: 500;
        border-radius: 0px 5px 5px 0px;
        &:hover,
        &:focus,
        &:focus-visible {
          outline: none;
          box-shadow: none;
          border: 0;
        }
      }
    }
  }
}
.balance-card,
.pnl-card {
  .box-info-content {
    width: 100%;
    height: 100%;
    align-items: flex-end;
    .box-right,
    .box-left {
      flex: 0 0 50%;
      max-width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      .cash {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #2f394e !important;
        font-weight: 600;
        font-size: clamp(19px, 2vw, 20px);
      }
      .title {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #6c757d;
        margin-left: 5px;
        font-weight: 600;
        font-size: clamp(0.7rem, 2vw, 0.8rem);
        text-transform: capitalize;
      }
    }
    .box-left {
    }
    .box-right {
    }
  }
}
