


































.chart-trading{
    height: 500px;
    border-radius: 5px;
}
